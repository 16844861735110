<template>
  <v-container class="py-0 my-0">
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
      location="bootom center"
      :timeout="snackbar.timeout"
      >{{ snackbar.message }}</v-snackbar
    >
  </v-container>
</template>
<script>
export default {
  name: "SnackbarComponent",
  props: ["snackbar"],
};
</script>
